<response-summary>
  <x-card edit={props.edit} onselect={props.onselect} ondelete={props.ondelete}>
    <template slot="content">
      <div class="summary">{props.data.response}</div>
      <x-label>{state.info}</x-label>
    </template>
    <template if={!props.data.runId} slot="actions">
      <x-icon icon="append" onclick={onNewPrompt}></x-icon>
    </template>
  </x-card>

  <style>
    .summary {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
              line-clamp: 3; 
      -webkit-box-orient: vertical;
    }
  </style>

  <script>
    import { router } from '@riotjs/route';
    export default {
      onBeforeMount() {
        //debug("response-summmary.onBeforeMount", this.props, this.state);
        let result = this.props.data.result;
        const info = [
          result ? result.model : undefined,
          result ? '$' + computeCost(result && result.model, result && result.usage) : undefined,
          this.props.data.duration ? this.props.data.duration/1000 + 's' : undefined,
        ];
        this.state.info = info.filter(x => x).join(', ');
      },
      async onNewPrompt() {
        // create new prompt with same data
        const schema = getSchema("prompt");
        const prompt = await get(`/api/prompts/${this.props.data.promptId}`);

        // add the response to the responses
        prompt.messages.push({
          role: "assistant",
          content: this.props.data.response
        });

        prompt.access = undefined;

        // construct the update
        const update = constructUpdate(Object.keys(schema.properties), prompt);
        
        // do not copy the responses
        delete update.responses;

        // add "copy" to the title
        update.title = `${update.title} response`;

        debug("response-summary.onNewPrompt", update);

        // send the new entity to the server
        const result = await post(`/api/prompts`, update);

        // notify the parent
        this.props.onneedupdate && this.props.onneedupdate();

        // navigate to the new prompt
        router.push(`/prompts/${result._id}`);
      }
    }
  </script>  
</response-summary>