<response class="d-flex flex-column row-gap-2">
  <template if={props.data && state.schema && state.schemaArgs}>
    <!--<x-label>prompt: {state.promptTitle}</x-label>-->
    <x-fields schema={state.schema} data={props.data} edit={props.edit} onchangevalue={props.onchangevalue}></x-fields>
    <x-field if={state.duration !== undefined} schema={{type:"string"}} name="duration" data={state.duration + 's'}></x-field>
    <x-field if={state.cost !== undefined} schema={{type:"string"}} name="cost" data={'$' + state.cost}></x-field>
    
    <div class="">
      <div class="row">
        <div class="col">
          <x-label><b>Args</b></x-label>
          <x-fields schema={state.schemaArgs} data={props.data.args} edit={false} name="args"></x-fields>
        </div>
        <div class="col">
          <x-label><b>API response</b></x-label>
          <x-fields schema={state.schemaResult} data={props.data.result} edit={false} name="args"></x-fields>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    async onBeforeMount() {
      //debug('response.onBeforeMount', this.props, this.state);
      this.state.schema = getSchema("response");

      // hide the api and args fields
      delete this.state.schema.properties.api;
      delete this.state.schema.properties.args;
      delete this.state.schema.properties.result;

      // convert the duration to seconds
      delete this.state.schema.properties.duration;
      this.state.duration = this.props.data.duration / 1000;

      // retrieve the title of the prompt
      const promptPath = this.props.path.split('/').slice(0, -2).join('/');
      const prompt = await get(`/api/${promptPath}`);
      this.state.promptTitle = prompt && prompt.title;
      //debug("response.onBeforeMount 2", this.state);

      // make response uneditable
      this.state.schema.properties.response.editable = false;

      this.update();
    },
    onMounted(){
      //debug("response.onMounted", this.state.schema, this.props.data);
      this.update();
    },
    onBeforeUpdate() {
      //debug('response.onBeforeUpdate', this.props, this.state);
      // get the api that was used (make sure that it will be undefined if not found)
      this.state.api = this.props.data.api;

      // get the args schema
      if (this.state.api) {
        this.state.schemaArgs = getSchema(`response_${this.state.api}_args`);
        this.state.schemaResult = getSchema(`response_${this.state.api}_result`);
      }

      // compute the cost
      const result = this.props.data.result;
      this.state.cost = computeCost(result && result.model, result && result.usage);
    }
  }
  </script>
</response>