<response-new>
  <popup title="Add a new response">
    <div slot="body" class="d-flex flex-column row-gap-2">
      <div>
        <div each={api in apis} class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id={`radio${api.key}`}
            value={api.key} onchange={onChange.bind(this, "api")} checked={api.key == state.api} />
          <label class="form-check-label" for={`radio${api.key}`}>{api.label}</label>
        </div>
      </div>

      <template each={api in apis} if={state.api == api.key}>
        <x-field if={api.key == "manual"} schema={{type: "string", textarea: true}} edit={true} name="response"
          value={state.data.manual && state.data.manual.response}
          onchange={onChangeData.bind(this, "manual", "response")}></x-field>

        <x-fields if={state.schema[api.key]} schema={state.schema[api.key]}
          data={state.data[api.key]} edit={true} onchangevalue={onChangeData.bind(this, api.key)}></x-fields>

        <x-label if={api.key != "manual"}>Note: this will result in a paid call to {api.label}.</x-label>
      </template>
    </div>
    <x-ok-cancel slot="footer" ok="Add" onok={onAdd.bind(this, onclose)} oncancel={onclose} busy={state.busy}></x-ok-cancel>
  </popup>

  <script>
  import { OpenAI } from "openai";
  export default {
    apis: [{key: "openai", label: "OpenAI"}, {key: "manual", label: "Manual"}],
    state: {
      api: "openai",
      schema: {},
      data: {}
    },
    onMounted() {
      //debug("response-new.onMounted", this.props, this.state);
      // by default load defaults from localStorage
      let defaults = JSON.parse(localStorage.getItem("defaults") || "{}");

      // overwrite defaults with the defaults from the template, if any
      Object.assign(defaults, this.props.template && this.props.template.defaults);

      for (let api of this.apis) {
        this.state.schema[api.key] = getSchema(`response_${api.key}_args`);
        this.state.data[api.key] = {};

        const properties = this.state.schema[api.key].properties;
        debug("response-new.onMounted 1", properties)
        for (let key in properties) {
          // overwrite the default if it's in the defaults, otherwise leave it as is in the schema
          if (properties[key] && defaults && defaults[api.key] && defaults[api.key][key]) {
            properties[key].default = defaults[api.key][key];
          }
        }
      }
      // set the data to the defaults, so that they get applied correctly on the server
      this.state.data = Object.assign({}, this.state.data, defaults);

      this.update();
    },
    onBeforeUpdate() {
      //debug("response-new.onBeforeUpdate", this.props, this.state);
    },
    onChangeData(api, key, e) {
      //debug("response-new.onChangeData", api, key, e.target.value, this.state.data);
      const value = e.target.value;
      this.state.data[api][key] = value;

      // update the default in the localStorage
      let defaults = JSON.parse(localStorage.getItem("defaults") || "{}");
      defaults[api] = defaults[api] || {};
      defaults[api][key] = value;
      localStorage.setItem("defaults", JSON.stringify(defaults));

      this.update();
    },
    onChange(key, e) {
      this.state[key] = e.target.value;
      this.update();
    },
    async onAdd(onclose) {
      this.update({busy: true});

      let path = this.props.path ? this.props.path : await this.props.getPromptPath();

      // remove the trailing "/responses" from the path, e.g. if called from x-cards
      path = path.replace("/responses", "")

      const apiKey = this.state.api;

      // construct the update from the state
      const update = this.state.data[apiKey];

      debug("response-new.onAdd", update, this.state, this.props, path);
      //const res = await post(`/api/${path}/${this.state.api}`, update);

      const prompt = await get(`/api/${path}`);
      
      let responseResult;
      if (apiKey == "manual") {
        responseResult = await post(`/api/${path}/manual`, update);

      } else if (apiKey == "openai") {
        try {
            const openai_key = window.localStorage.getItem("openai_key");
            if (!openai_key) {
              this.update({busy: false});
              alert("Please set your OpenAI API key in the settings.");
              return;
            }
            
            // keep only the role and content fields of the messages
            const messages = prompt.messages.map(message => {
                return {
                    role: message.role,
                    content: message.content
                };
            });

            const request = Object.assign({}, update, { messages: messages });
            debug("request", request);

            const beginTime = (new Date()).getTime();
            
            const openai = new OpenAI({
              apiKey: openai_key,
              dangerouslyAllowBrowser: true
            });
            const openaiResult = (await openai.chat.completions.create(request));
            
            debug("result", openaiResult);
            
            const endTime = (new Date()).getTime();
            const duration = endTime - beginTime;

            responseResult = await post(`/api/${path}/openai`, {request: request, result: openaiResult, duration: duration});

        } catch (err) {
            console.log(JSON.stringify(err));

            this.update({busy: false});
            alert(err.message + (err.code == "invalid_api_key" ? "\n\nPlease set your OpenAI API key in the settings." : ""));
        }
      }



      // clear the data
      for (let api of this.apis) {
        this.state.data[api.key] = {};
      }

      this.update({busy: false});

      // close the popup
      onclose && onclose();
      
      if (responseResult) {
        const promptId = extractId(path, "prompts");
        this.props.onadd && this.props.onadd(promptId, responseResult._id);
      } else {
        alert("Something went wrong. Please try again later.");
      }
    }
  }
  </script>
</response-new>