<template-summary>
  <x-summary type="template" name={props.name} path={props.path}
    data={props.data} edit={props.edit} onselect={props.onselect} ondelete={props.ondelete} onneedupdate={props.onneedupdate}>
    <template if={state.showPlay} slot="actions">
      <x-icon icon="play" onclick={onPlay}></x-icon>
    </template>
  </x-summary>

  <script>
  import { router } from '@riotjs/route';
  export default {
    onMounted() {
      this.update();
    },
    onBeforeUpdate() {
      //debug("template-summary.onBeforeUpdate", this.props, this.state)

      // extract runId, pipelineId from the path
      const runId = global.extractId(this.props.path, "runs");
      const pipelineId = global.extractId(this.props.path, "pipelines");

      // set path prefix if part of a run or pipeline
      if (runId) {
        this.state.prefix = `/pipelines/${pipelineId}/runs/${runId}`
        this.state.showPlay = false;
      } else if (pipelineId) {
        this.state.prefix = `/pipelines/${pipelineId}`;
        this.state.showPlay = false;
      } else {
        this.state.prefix = "";
        this.state.showPlay = true;
      }
    },
    onPlay() {
      const templateId = global.extractId(this.props.path, "templates");
      router.push(`${this.state.prefix}/templates/${templateId}/form`);
    }
  }
  </script>
</template-summary>