<run-edit>
  <x-entity-edit type="run" title="Run" path={state.path} show-edit={['title', 'tags', 'templates']} show-add={['templates']} onfetch={onFetch}>
    <template if={state.cost || state.duration}>
      <x-label class="card-label mt-2">Stats</x-label>
      <x-field schema={{type:"string"}} name="duration" data={state.duration + 's'}></x-field>
      <x-field schema={{type:"string"}} name="cost" data={'$' + state.cost}></x-field>
    </template>
    <template if={state.final}>
      <x-label class="card-label mt-2">Final response</x-label>
      <x-field schema={{type:"string"}} data={state.final}></x-field>
    </template>
  </x-entity-edit>

  <x-button-container if={state.showPlay}>
    <x-button onclick={onPlay}>Run</x-button>
  </x-button-container>
  <style>
    :host {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .card-label {
      display: block;
      font-size: 1.1rem;
      font-weight: 600;
    }
  </style>

  <script>
  import { router } from '@riotjs/route';
  export default {
    onFetch(run) {
      //debug('run-edit.onFetch', run);
      this.state.templateId = nextTemplateId(run);
      this.state.showPlay = !!this.state.templateId;
      this.state.path = global.getPath();

      // compute the cost and duration for the first responses in all the prompts
      let cost = 0;
      let duration = 0;
      debug('run-edit.onFetch', run, this.state);
      (run.templates || []).forEach(template => {
        const responses = template.prompts && template.prompts.length && template.prompts[template.prompts.length - 1].responses;
        if (responses && responses.length > 0) {
          // get the last response
          const response = responses[responses.length - 1];
          const result = response.result;
          
          cost += computeCost(response.result && response.result.model, response.result && response.result.usage);
          duration += response.duration / 1000;
        }
      });
      this.state.cost = cost;
      this.state.duration = duration;

      // if there is no templateId, then show the final response
      if (!this.state.templateId) {
        const prompts = run.templates[run.templates.length - 1].prompts || [];
        const responses = prompts && prompts.length && prompts[prompts.length - 1].responses;
        if (responses && responses.length > 0) {
          this.state.final = responses[responses.length - 1].response;
        }
      }
      //debug("run-edit.onFetch cost", this.state)
      this.update();
    },
    async onPlay() {
      debug('play', this.props);
      
      router.push(`/pipelines/${this.props.pipelineId}/runs/${this.props.runId}/templates/${this.state.templateId}/form`);
    }
  }
  </script>

</run-edit>