<template-ed>
  <template if={props.data && state.schema}>
    <x-fields schema={state.schema} data={props.data} edit={props.edit} path={props.path}
      onchangevalue={props.onchangevalue} onneedupdate={props.onneedupdate}></x-fields>

    <x-button-container if={state.showPlay}>
      <x-button onclick={onPlay}>Run template</x-button>
    </x-button-container>

   <x-field if={state.showPlay} name="prompts" schema={state.schemaPrompts} data={props.data.prompts} edit={true} path={props.path} show-add={false}
      onchangevalue={props.onchangevalue} onneedupdate={props.onneedupdate}></x-field>

  </template>
  <style>
    :host {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  </style>

  <script>
  import { router } from '@riotjs/route';
  export default {
    state: {
      prefix: '',
      showPlay: true,
      showEdit: true
    },
    onBeforeMount() {
      //debug('template.onBeforeMount', this.props, this.state);
      this.state.schema = clone(this.props.schema);

      // save the prompts schema for later
      this.state.schemaPrompts = this.state.schema.properties.prompts;

      // remove the prompts schema from the main schema
      delete this.state.schema.properties.prompts;
    },
    onMounted() {
      this.update();
    },
    onBeforeUpdate() {
      debug('template.onBeforeUpdate', this.props, this.state);
      
      // set path prefix if part of a run or pipeline
      const runId = global.extractId(this.props.path, "runs");
      const pipelineId = global.extractId(this.props.path, "pipelines");
      if (runId) {
        this.state.prefix = `/pipelines/${pipelineId}/runs/${runId}`
        this.state.showPlay = true;
        this.state.showEdit = true;
      } else if (pipelineId) {
        this.state.prefix = `/pipelines/${pipelineId}`;
        this.state.showPlay = false;
        this.state.showEdit = false;
      }
    },
    onPlay() {
      const templateId = global.extractId(this.props.path, "templates");
      router.push(`${this.state.prefix}/templates/${templateId}/form`);
    }
  }
  </script>

</template-ed>