import * as riot from 'riot';

export default function PopupMixin (component) {
  component.showPopup = function(name, args) {
    var node = document.createElement("div");
    document.querySelector('app').appendChild(node);
    return riot.mount(node, args, name);
  }

  component.pushPopup = function(name, args) {
    // remove the tags of the previous popup
    if (PopupMixin.popups.length) {
      var popup = PopupMixin.popups[PopupMixin.popups.length-1];
      (popup.tags || []).forEach(function (tag) {
        //tag.root.style.display = "none";
        tag.$('.modal').modal('hide');
      });
    }

    var tags = this.showPopup(name, args);
    PopupMixin.popups.push({name: name, args: args, tags: tags});
  }

  component.popPopup = function() {
    // remove the tags of the poped popup
    if (PopupMixin.popups.length) {
      var popup = PopupMixin.popups[PopupMixin.popups.length-1];
      (popup.tags || []).forEach(function (tag) {
        //tag.$('.modal').modal('hide');
        tag.unmount()
      });
      popup.tags = undefined;
    }
    
    // pop to current screen
    PopupMixin.popups.pop();
    
    // show the previous screen (saving the new tags reverences)
    if (PopupMixin.popups.length) {
      var popup = PopupMixin.popups[PopupMixin.popups.length-1];
      (popup.tags || []).forEach(function (tag) {
        //tag.root.style.display = "block";
        tag.$('.modal').modal('show');
      });
    }
  }

  component.popAllPopups = function () {
    while (PopupMixin.popups.length) {
      this.popPopup();
    }
  }

  return component;
}

PopupMixin.popups = [];