<signup class="mt-5">
  <x-fields schema={state.schema} edit={true} feedback={state.feedback} onchangevalue={onChange}></x-fields>
  <div class="d-flex justify-content-between my-3">
    <x-button onclick={onSubmit} fillx={true}>Sign up</x-button>
  </div>

  <script>
  export default {
    state: {
      schema: {
        type: 'object',
        properties: {
          email: {
            type: 'string'
          },
          password: {
            type: 'string',
            password: true
          },
          password_verify: {
            type: 'string',
            label: "verify password",
            password: true
          }
        },
      },
      feedback: {}
    },
    onChange(key, e) {
      this.state[key] = e.target.value;
      debug("signup.onChange", key, this.state);
    },
    async onSubmit() {
      const feedback = {
        email: this.state.email ? undefined : 'Please enter your email',
        password: this.state.password ? undefined : 'Please enter your password',
        password_verify: this.state.password_verify ? undefined : 'Please enter your password',
      };

      if (feedback.password_verify === undefined && this.state.password !== this.state.password_verify) {
        feedback.password_verify = "Passwords don't match";
      }

      this.state.feedback = feedback;
      this.update();
      
      debug("signup.validateForm", this.state);

      if (feedback.email || feedback.password || feedback.password_verify) return;

      const res = await post("/auth/email/signup", {
        email: this.state.email,
        password: this.state.password
      });
      if (res.error) {
        this.state.feedback = { password_verify: res.error };
      } else {
        this.state.feedback = {};
      }
      this.update();
    }
  };
  </script>
</signup>