<x-select>
  <select class="form-select" onchange={onChange.bind(this, "selected")}>
    <option each={value in state.values} value={value} selected={value == state.selected}>{value}</option>
  </select>
  <input if={state.selected == "other"} type="text" class="form-control mt-2" placeholder="enter a value" value={state.other} onchange={onChange.bind(this, "other")} />

  <script>
  export default {
    onMounted(){
      this.update();
    },
    onBeforeUpdate() {
      // show an empty option only if no value is selected
      if (this.props.value) {
        this.state.values = this.props.values;
      } else {
        this.state.values = [null].concat(this.props.values);
      }

      if (this.props.other) {
        this.state.values.push("other");
      }
      if (this.props.value) {
        this.state.selected = this.state.values.indexOf(this.props.value) > -1 ? this.props.value : "other";
        this.state.other = this.state.values.indexOf(this.props.value) > -1 ? undefined : this.props.value;
      }
    },

    onChange(key, e) {
      this.state[key] = e.target.value;
      const value = this.state.selected == "other" ? this.state.other : this.state.selected;
      this.props.onchangevalue && this.props.onchangevalue({target: {value: value}})
      //this.update();
    }
  }
  </script>
</x-select>