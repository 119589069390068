<x-entity-edit class="mt-4 w-100">
  <x-container if={state.data}>
    <h5 class="d-flex justify-content-between">
      <x-breadcrumbs path={state.path}></x-breadcrumbs>
      <div>
        <span class="mx-3"><slot name="tools"></slot></span>
        <x-icon icon="edit" if={state.showEdit} active={state.edit} onclick={onToggleEdit}></x-icon>
      </div>
    </h5>

    <div if={props.component} is={props.component} schema={state.schema} data={state.data} path={state.path} edit={state.edit}
      onchangevalue={onChange} onneedupdate={fetch}></div>

    <x-fields if={!props.component} schema={state.schema} data={state.data} path={state.path} edit={state.edit} show-edit={props.showEdit} show-add={props.showAdd}
      onchangevalue={onChange} onneedupdate={fetch}></x-fields>
    <!--<x-ok-cancel if={state.edit} ok="Save" onok={onSave} oncancel={onToggleEdit}></x-ok-cancel>-->
    <slot />
  </x-container>

  <x-container if={state.error}>
    <x-error>{state.error}</x-error>
  </x-container>

  <style>
    .host {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  </style>

  <script>
  export default {
    state: {
      changes: {},
      edit: true
    },
    onMounted() {
      //debug("x-entity-edit.onBeforeMount", this.props)
      this.state.path = global.getPath();

      this.update();
    },
    onBeforeUpdate() {
      this.state.showEdit = this.props.showEdit === undefined || this.props.showEdit;
      this.state.path = global.getPath();

      debug("x-entity-edit.onBeforeUpdate", this.props, this.state);
      if (this.state.path != this.state.lastPath) {
        this.state.lastPath = this.state.path;
        this.fetch();
      }
    },
    onChange(key, e) {
      //debug("x-entity-edit.onChange", key, e);

      // set the change resursively, with key speicifying the object path
      let path = key.split(".");
      let obj = this.state.changes;
      while (path.length > 1) {
        // create the object if it doesn't exist
        if (!obj[path[0]]) {
          obj[path[0]] = {};
        }
        // move down the path
        obj = obj[path.shift()];
      }
      obj[path[0]] = e.target.value;

      this.onSave();
    },
    async fetch() {
      // get the schema
      this.state.schema = getSchema(this.props.type);

      // remove the access field if not at the root
      let parts = this.state.path.split("/");
      if (parts.length > 2) {
        delete this.state.schema.properties.access;
      }

      debug("x-entity-edit.fetch", this.state);

      // get the data from the server
      let json;
      try {
        json = await get(`/api/${this.state.path}`);
        this.state.data = json;
        this.state.error = undefined;

        this.props.onfetch && this.props.onfetch(json);
      } catch (e) {
        if (e.status == 403) {
          this.state.data = undefined;
          this.state.error = `Sorry, you are not authorized to view this ${this.props.type}. Try going back to the previous page.`;
        } else {
          console.error(`Error trying to get /api/${this.state.path}`, e);
          this.state.data = undefined;
          this.state.error = `Sorry, there was an error fetching the ${this.props.type}. Try going back to the previous page.`;
        }
      }
      
      this.update();
    },
    onToggleEdit() {
      this.update({edit: !this.state.edit});
    },
    async onSave() {
      // construct the update from the state
      const update = constructUpdate(Object.keys(this.state.schema.properties), this.state.changes);
      
      // send the update to the server
      try {
        await put(`/api/${this.state.path}`, update);
      } catch (e) {
        debug("x-entity-edit.onSave", e);
        if (e.status == 403) {
          alert(`Sorry, you are not authorized to modify this ${this.props.type}.`);
        } else {
          console.error(`Error trying to put /api/${this.state.path}`, e);
          alert(`Sorry, there was an error modifying the ${this.props.type}.`);
        }
      }

      // turn off edit mode
      //this.state.edit = false;

      // get the data again
      this.fetch();
    }
  }
  </script>
</x-entity-edit>