<app>

  <nav class="navbar navbar-expand sticky-top shadow-sm">
  
    <x-container>
      <div class="d-flex align-items-baseline w-100">
        <a class="navbar-brand" href="/"><img src="/img/logo.png" height="25px"/></a>
        
        <div class="spacer1 flex-grow-1"></div>
        
        <div class="navbar-nav me-3">
          <a class={`nav-link ${state.active == "prompts" ? "active" : ""}`} onclick={onGo.bind(this, "/prompts")}>Prompts</a>
          <a class={`nav-link ${state.active == "templates" ? "active" : ""}`} onclick={onGo.bind(this, "/templates")}>Templates</a>
          <a class={`nav-link ${state.active == "pipelines" ? "active" : ""}`} onclick={onGo.bind(this, "/pipelines")}>Pipelines</a>
        </div>

        <div class="spacer2 flex-grow-1"></div>

        <div class="d-flex align-items-baseline">
          <div if={state.loggedIn} class="nav-item dropdown">
            <x-icon class="" icon="settings" data-bs-toggle="dropdown" aria-expanded="false"></x-icon>
        
            <ul class="dropdown-menu dropdown-menu-end">
              <li><button class="dropdown-item" type="button" onclick={onSettings}>Settings</button></li>
              <li><hr class="dropdown-divider"></li>
              <li><button class="dropdown-item" type="button" onclick={onLogout}>Log out</button></li>
            </ul>
          </div>
          <button if={state.loggedIn == false} class="btn btn-outline-primary text-nowrap btn-sm" onclick={onGo.bind(this, "/login")}>Log in</button>
        </div>
      </div>
    </x-container>
  
  </nav>

  <router>
    <route path="(/\?.*|/)">
      <home></home>
    </route>
    <route path="/login">
      <login></login>
    </route>
    <route path="/settings(/#.*|)">
      <settings></settings>
    </route>
    <route path="/signup">
      <signup></signup>
    </route>
    <route path="/prompts">
      <x-entity-list type="prompt"></x-entity-list>
    </route>
    <route path="/prompts/:id">
      <x-entity-edit type="prompt" title="Prompt" component="prompt"></x-entity-edit>  
    </route>
    <route path="/prompts/:prompt/responses/:response">
      <x-entity-edit type="response" title="Response" component="response"></x-entity-edit>
    </route>
    <route path="/prompts/:prompt/messages/:message">
      <message-edit prompt-id={route.params.prompt}></message-edit>
    </route>

    <route path="/templates">
      <x-entity-list type="template" title="Templates"></x-entity-list>
    </route>
    <route path="/templates/:id">
      <x-entity-edit type="template" title="Template" component="template-ed"></x-entity-edit>  
    </route>
    <route path="/templates/:id/form">
      <template-form template-id={route.params.id} 
        path={`templates/${route.params.id}`}></template-form>  
    </route>
    <route path="/templates/:template/messages/:message">
      <x-entity-edit type="message" title="Template message"></x-entity-edit>
    </route>
    <route path="/templates/:template/prompts/:prompt">
      <x-entity-edit type="prompt" title="Prompt" component="prompt"></x-entity-edit>  
    </route>
    <route path="/templates/:template/prompts/:prompt/messages/:message">
      <x-entity-edit type="message" title="Template prompt message"></x-entity-edit>
    </route>
    <route path="/templates/:template/prompts/:prompt/responses/:response">
      <x-entity-edit type="response" title="Response" component="response"></x-entity-edit>
    </route>

    <route path="/pipelines">
      <x-entity-list type="pipeline" title="Pipelines"></x-entity-list>
    </route>
    <route path="/pipelines/:pipeline">
      <x-entity-edit type="pipeline" title="Pipeline" component="pipeline"></x-entity-edit>
    </route>
    <route path="/pipelines/:pipeline/templates/:template">
      <x-entity-edit type="template" title="Template" component="template-ed"></x-entity-edit>
    </route>
    <route path="/pipelines/:pipeline/templates/:id/form">
      <template-form template-id={route.params.id} pipeline-id={route.params.pipeline}
        path={`pipelines/${route.params.pipeline}/templates/${route.params.id}`}></template-form>
    </route>
    <route path="/pipelines/:pipeline/templates/:template/messages/:message">
      <x-entity-edit type="message" title="Pipeline template message"></x-entity-edit>
    </route>

    <route path="/pipelines/:pipeline/runs/:run">
      <run-edit run-id={route.params.run} pipeline-id={route.params.pipeline}></run-edit>  
    </route>
    <route path="/pipelines/:pipeline/runs/:run/templates/:template">
      <x-entity-edit type="template" title="Template" component="template-ed"></x-entity-edit>
    </route>
    <route path="/pipelines/:pipeline/runs/:run/templates/:id/form(.*)">
      <template-form template-id={route.params.id} run-id={route.params.run} pipeline-id={route.params.pipeline} 
        path={`pipelines/${route.params.pipeline}/runs/${route.params.run}/templates/${route.params.id}`}></template-form>
    </route>
    <route path="/pipelines/:pipeline/runs/:run/templates/:template/messages/:message">
      <x-entity-edit type="message" title="Run template message"></x-entity-edit>
    </route>

    <route path="/pipelines/:pipeline/runs/:run/templates/:template/prompts/:prompt">
      <x-entity-edit type="prompt" title="Prompt" component="prompt"></x-entity-edit>  
    </route>
    <route path="/pipelines/:pipeline/runs/:run/templates/:template/prompts/:prompt/messages/:message">
      <x-entity-edit type="message" title="Run prompt message"></x-entity-edit>
    </route>
    <route path="/pipelines/:pipeline/runs/:run/templates/:template/prompts/:prompt/responses/:response">
      <x-entity-edit type="response" title="Response" component="response"></x-entity-edit>
    </route>

  </router>

  <style>
    route {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow-x: clip;
    }
    .navbar-brand {
      padding-top: 0;
      padding-bottom: 0;
      top: 1px;
      position: relative;
    }
    .navbar {
      background-color: var(--bs-light-bg-subtle);
    }

    .spacer2 {
      display: none;
    }
    @media (max-width: 576px) {
      .navbar-brand {
        display: none;
      }
      .spacer1 {
        display: none;
      }
      .spacer2 {
        display: block;
      }
      .navbar-expand .navbar-nav .nav-link:first-child {
        padding-left: 0;
      }
    }
  </style>

  <script>
    import { router } from '@riotjs/route';
    export default {
      async onMounted() {
        // triggered on each route event
        let that = this;
        router.on.value(path => {
          document.title = `Prompter${that.state.active ? ` - ${that.state.active.capitalize()}` : ""}`;

          // determine the active tab from the path
          let active = global.getPath().split("/")[0];
          that.update({active: active});
        })

        // logedIn is an object { settings: {...} }
        global.loggedIn = await get("/auth/isLoggedIn");
        debug("logged in", global.loggedIn);

        // clear the OpenAI key if not logged in (e.g. after a session timeout)
        if (global.loggedIn == false) {
          localStorage.removeItem("openai_key");
          localStorage.removeItem("defaults");
        }

        this.update();
      },
      async onBeforeUpdate() {
        // triggered on each route event
        document.title = `Prompter${this.state.active ? ` - ${this.state.active.capitalize()}` : ""}`;

        this.state.loggedIn = global.loggedIn != false;
      },
      onGo(path) {
        router.push(path);
      },
      onSettings() {
        router.push("/settings");
      },
      onLogout() {
       localStorage.removeItem("openai_key");
       localStorage.removeItem("defaults");
       window.location.href = "/auth/logout";
      }
    }
  </script>
</app>