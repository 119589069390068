<!--
  @param: props = {
    name: string,
    data: any,
    schema: object,
    edit: boolean,
    onchangevalue: function
  }
-->
<x-field>
  <x-label if={props.name && props.schema.type != "object" && props.schema.type != "array"}>{props.schema.label ? props.schema.label : props.name}
    <x-tool if={props.schema.description} tip={props.name} />
  </x-label>
  <x-label if={props.name && props.schema.type == "object" && props.schema.type != "array"}><b>{props.schema.label ? props.schema.label : props.name}</b></x-label>

  <x-tip if={props.schema.description} tip={props.name} html={props.schema.description}></x-tip>

  <template if={!state.edit && props.schema.type != "object" && props.schema.type != "array"}>
    <div class={`value ${props.schema.disabled == true ? "disabled" : ""}`}>{state.data}</div>
  </template>

  <template if={state.edit && props.schema.type == "string" && props.schema.textarea}>
    <textarea class="form-control" value={state.data} onchange={onChange.bind(this, props.name)}></textarea>
  </template>

  <template if={state.edit && props.schema.type == "string" && !props.schema.enum && !props.schema.textarea && !props.schema.password}>
    <input type="text" class="form-control" value={state.data} onchange={onChange.bind(this, props.name)} />
  </template>

  <template if={state.edit && props.schema.type == "string" && !props.schema.enum && !props.schema.textarea && props.schema.password}>
    <input type="password" class="form-control" value={state.data} onchange={onChange.bind(this, props.name)} />
  </template>

  <template if={state.edit && props.schema.type == "string" && props.schema.enum}>
    <x-select values={props.schema.suggestions ? props.schema.suggestions : props.schema.enum}
      value={state.data} other={!!props.schema.suggestions} onchangevalue={onChange.bind(this, props.name)}></x-select>
  </template>

  <template if={state.edit && props.schema.type == "number"}>
    <input type="number" class="form-control" value={state.data} onchange={onChange.bind(this, props.name)} />
  </template>

  <template if={state.edit && props.schema.type == "boolean"}>
    <x-switch value={state.data} onchange={onChange.bind(this, props.name)}></x-switch>
  </template>

  <template if={props.schema.type == "object"}>
    <x-fields schema={props.schema} data={state.data} edit={state.edit} name={props.name}
      onchangevalue={onChange}></x-fields>
  </template>

  <template if={props.schema.type == "array"}>
    <x-cards schema={props.schema} data={state.data} edit={state.edit} name={props.name}
      path={props.path + "/" + props.name} active={props.active} show-add={props.showAdd}
      onneedupdate={props.onneedupdate}></x-cards>
  </template>

  <div if={props.feedback && props.schema.type != "object" && props.schema.type != "array"} class="text-danger">
    {props.feedback}
  </div>
  
  <div><slot/></div>

  <style>
    .value {
      white-space: pre-wrap;
    }
    .value.disabled {
      color: #999;
    }
  </style>

  <script>
  export default {
    onBeforeMount() {
      
    },
    onMounted() {
      this.update();
    },
    onBeforeUpdate() {
      //debug("x-field.onBeforeUpdate", this.props, this.state);
      this.state.data = this.props.data || (this.props.schema && this.props.schema.default);
      
      // emulate selecting the default value
      if (this.props.schema && this.props.schema.default && !this.props.data && !this.state.called_default) {
        // make sure we only call this once
        this.state.called_default = true;
        setTimeout((() => {
          this.props.onchangevalue && this.props.onchangevalue(this.props.name, { target: { value: this.props.schema.default }});
        }).bind(this));
      }

      this.state.edit = this.props.edit && this.props.schema.editable != false;
    },
    onChange(key, e) {
      let ee = { target: { value: e.target.value }};

      // convert value to correct type
      switch (e.target.type) {
        case "checkbox":
          ee.target.value = e.target.checked;
          break;
        case "number":
          ee.target.value = e.target.valueAsNumber;
          break;
      }

      this.props.onchangevalue && this.props.onchangevalue(key, ee);
    }
  }
  </script>

</x-field>