<pipeline class="d-flex flex-column row-gap-2">
  <template if={props.data && state.schema}>
    <x-fields schema={state.schema} data={props.data} edit={props.edit} path={props.path} 
      onchangevalue={props.onchangevalue} onneedupdate={props.onneedupdate}></x-fields>

    <x-button-container>
        <x-button onclick={onPlay}>Run pipeline</x-button>
    </x-button-container>
    
    <x-field name="runs" schema={state.schemaRuns} data={props.data.runs} edit={state.editRuns} path={props.path} show-add={false}
      onchangevalue={props.onchangevalue} onneedupdate={props.onneedupdate}></x-field>
  </template>

  <style>
    :host {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  </style>

  <script>
  import { router } from '@riotjs/route';
  export default {
    onBeforeMount() {
      debug('pipeline.onBeforeMount', this.props, this.state);
      this.state.schema = clone(this.props.schema);

      // get the responses schema
      this.state.schemaRuns = this.state.schema.properties.runs;

      // hide the api and args fields
      delete this.state.schema.properties.runs;
    },
    onMounted() {
      this.update();
    },
    onBeforeUpdate() {
      this.state.editRuns = (this.props.data.runs && this.props.data.runs.length > 0);
    },
    onPlay() {
      const pipelineId = global.extractId(this.props.path, "pipelines");
      global.playPipeline(pipelineId);
    }
  }
  </script>

</pipeline>