<home>
  <x-container>
    <div class="d-block flex-column">
      <h1 class="text-center mt-5 mb-5">
        Prompt Design and Management
      </h1>
      <p>
        Are you are struggling with managing your favorite prompts by copy-pasting them into Word?
      </p>
      <p>
        Prompter helps you design, manage and execute prompts and prompt pipelines.
      </p>
    </div>

    <x-button onclick={onLogin}>Get started</x-button>
  </x-container>


  <style>
  :host {
    width: 80%;
    text-align: center;
  }
  .d-block {
    margin-top: 150px;
    margin-bottom: 30px;
  }
  </style>

  <script>
  import { router } from '@riotjs/route';
  export default {
    onLogin() {
      router.push("/login");
    }
  }
  </script>
</home>