<x-button style={state.fillx ? 'width:100%' : ''}>
  <button class={`btn ${state.look} btn-sm ${state.fillx}`} type={props.type}><slot /></button>

  <style>
  .fillx, :host.fillx {
    width: 100%;
  }
  </style>

  <script>
  export default {
    onMounted() {
      this.update();
    },
    onBeforeUpdate() {
      const looks = {
        primary: 'btn-primary',
        secondary: 'btn-outline-secondary',
        success: 'btn-success',
        danger: 'btn-danger',
        warning: 'btn-warning',
        info: 'btn-info',
        light: 'btn-light',
        dark: 'btn-dark',
        link: 'btn-link'
      };
      this.state.look = looks[this.props.look || 'primary'];

      this.state.fillx = this.props.fillx ? 'fillx' : undefined;
    }
  }
  </script>
</x-button>