<pipeline-summary>
  <x-summary type="pipeline" name={props.name} path={props.path}
    data={props.data} edit={props.edit} onselect={props.onselect} ondelete={props.ondelete} onneedupdate={props.onneedupdate}>
    <template slot="actions">
      <x-icon icon="play" onclick={onPlay}></x-icon> 
    </template> 
  </x-summary>

  <script>
    export default {
      onPlay() {
        playPipeline(this.props.data._id);
      }
    }
  </script>
</pipeline-summary>