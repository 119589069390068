<x-entity-new>
  <popup title="Add a new {props.type}" >
    <x-fields slot="body" schema={state.schema} path={props.path} edit={true} onchangevalue={onChange}></x-fields>
    <x-ok-cancel slot="footer" ok="Add" onok={onAdd.bind(this, onclose)} oncancel={onclose}></x-ok-cancel>
  </popup>

  <script>
  export default {
    state: {
      schema: {},
      data: {}
    },
    onBeforeMount() {
      this.state.schema = getSchema(this.props.type);

      // remove properties with type "array"
      this.state.schema.properties = Object.keys(this.state.schema.properties).reduce((acc, key) => {
        if (this.state.schema.properties[key].type !== 'array') {
          acc[key] = this.state.schema.properties[key];
        }
        return acc;
      }, {});

      // remove access property if not root entity
      if (this.props.path.split('/').length > 1) {
        delete this.state.schema.properties.access;
      }
    },
    onChange(key, e) {
      this.state.data[key] = e.target.value;
      this.update();
    },
    async onAdd(onclose) {
      const { data, schema } = this.state;

      // construct the update
      const update = constructUpdate(Object.keys(schema.properties), data);
      
      // send the new entity to the server
      await post(`/api/${this.props.path}`, update);

      // close the popup
      onclose && onclose();

      // notify the parent
      this.props.onadd && this.props.onadd();
    }
  }
  </script>
</x-entity-new>