<x-entity-list class="mt-4 w-100">
  <x-container>
    <x-cards if={state.data} schema={state.schema} data={state.data} name={props.type + 's'} edit={true} show-add={props.showAdd}
      path={props.type + 's'} title={props.title} title-class="h5 mb-3" onneedupdate={fetch}></x-cards>
  </x-container>

  <style>
    .host {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  </style>

  <script>
  import { router } from '@riotjs/route';
  export default {
    async fetch() {
      let data = await get(`/api/${this.props.type}s`);
      this.state.data = data;
      this.update();
    },
    async onBeforeMount() {
      this.state.schema = {
        type: "array",
        items: {
          $ref: `${this.props.type}.json`,
          name: this.props.type
        }
      }
      await this.fetch();
    }
  }
  </script>
</x-entity-list>