<login class="mt-5">
  <x-fields schema={state.schema} edit={true} feedback={state.feedback} onchangevalue={onChange} onenter={onLoginEmail}></x-fields>
  <div class="d-flex justify-content-between my-3">
    <x-button onclick={onLoginEmail} fillx={true}>Log in</x-button>
  </div>
  <div>
    Don't have an account? <a href="/signup">Sign up</a>
  </div>

  <div class="or">or</div>
  
  <x-button onclick={onLoginGoogle} look="secondary" fillx={true}>
    <div class="federated"><span class="googleLogo"></span><span class="text">Log in with Google</span></div>
  </x-button>


  <style>
  .or {
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    margin: 20px 0;
    color: #2d333a;
    font-weight: 200;
  }
  .or:before, .or:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #c2c8d0;
    margin: auto;
  }
  .or:before {
    margin-right: 10px
  }
  .or:after {
    margin-left: 10px
  }

  .federated {
    display: flex;
    position: relative;
    padding: 5px 10px;
  }
  .federated .text {
    margin-left: 20px;
  }
  .googleLogo {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'
      xmlns:xlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 48 48'
      %3E%3Cdefs%3E%3Cpath
      id='a'
      d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6
      4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'
      /%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a'
      overflow='visible'/%3E%3C/clipPath%3E%3Cpath clip-path='url(%23b)'
      fill='%23FBBC05' d='M0 37V11l17 13z'/%3E%3Cpath clip-path='url(%23b)'
      fill='%23EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z'/%3E%3Cpath clip-path='url(%23b)'
      fill='%2334A853' d='M0 37l30-23 7.9 1L48 0v48H0z'/%3E%3Cpath clip-path='url(%23b)'
      fill='%234285F4' d='M48 48L17 24l-4-3 35-10z'/%3E%3C/svg%3E");
 
    position: absolute;
    left: 26px;
    left: calc(var(--spacing-6-5)/2);
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  </style>

  <script>
  export default {
    state: {
      schema: {
        type: 'object',
        properties: {
          email: {
            type: 'string'
          },
          password: {
            type: 'string',
            password: true
          },
        },
      },
      feedback: {}
    },
    onChange(key, e) {
      this.state[key] = e.target.value;
      debug("login.onChange", key, this.state);
    },
    async onLoginEmail() {
      this.state.feedback = {
        email: this.state.email ? undefined : 'Please enter your email',
        password: this.state.password ? undefined : 'Please enter your password',
      };
      this.update();
      
      debug("login.onLogin", this.state);

      if (this.state.email && this.state.password) {
        let res;
        try {
          res = await post("/auth/email/login", {email: this.state.email, password: this.state.password});
        } catch (e) {
          debug("error", e.status, e.json);
          if (e.json && e.json.error) {
            res = e.json;
          } else {
            res = { error: "Unknown error" };
          }
        }
        if (res && res.error) {
          this.state.feedback = { password: res.error };
        } else {
          this.state.feedback = {};
        }
        this.update();
      }
    },
    onLoginGoogle() {
      debug("login.onLoginGoogle");
      window.location.href = "/auth/google/login";
    }
  };
  </script>
</login>