<x-card class="d-flex flex-row align-stretch mt-2">
  <div class="card shadow-sm" onclick={props.onselect}>
    <div class="card-body d-flex justify-content-between m-2">
      <div class="d-block">
        <slot name="content" />
      </div>
      <div class="d-flex align-items-center">
        <div if={props.public} class="badge bg-secondary">public</div>
      </div>
    </div>
  </div>
  
  <div if={props.edit} class="edit d-flex align-items-center">
    <x-icon icon="delete" onclick={props.ondelete}></x-icon>
    <slot name="edit" />
  </div>

  <div class="edit d-flex align-items-center">
    <slot name="actions" />
  </div>

  <style>
  :host {
    align-items: stretch;
  }
  .card {
    flex: 1;
    --bs-card-spacer-x: .5rem;
    --bs-card-spacer-y: .3rem;
    border: none;
  }
  .edit > * {
    margin-left: 1rem !important;
  }
  </style>
</x-card>