/*
 * This file is automatically generated by running `npm run update-schemas`
 */

let schemas = {};

export function getSchema(type) {
    if (!schemas[type]) {
      throw new Error(`Schema not found for type '${type}'`);
    }

    return JSON.parse(JSON.stringify(schemas[type]));
}

export function addSchema(type, json) {
    if (schemas[type]) {
      throw new Error(`Schema already defined for type '${type}'`);
    }

    schemas[type] = json;
}

export function getSchemaKeys() {
    return Object.keys(schemas);
}

import message_schema from './message.json';
schemas['message'] = message_schema;

import openai_call_schema from './openai_call.json';
schemas['openai_call'] = openai_call_schema;

import pipeline_schema from './pipeline.json';
schemas['pipeline'] = pipeline_schema;

import prompt_schema from './prompt.json';
schemas['prompt'] = prompt_schema;

import response_schema from './response.json';
schemas['response'] = response_schema;

import response_manual_args_schema from './response_manual_args.json';
schemas['response_manual_args'] = response_manual_args_schema;

import response_manual_result_schema from './response_manual_result.json';
schemas['response_manual_result'] = response_manual_result_schema;

import response_openai_args_schema from './response_openai_args.json';
schemas['response_openai_args'] = response_openai_args_schema;

import response_openai_result_schema from './response_openai_result.json';
schemas['response_openai_result'] = response_openai_result_schema;

import response_ref_schema from './response_ref.json';
schemas['response_ref'] = response_ref_schema;

import run_schema from './run.json';
schemas['run'] = run_schema;

import template_schema from './template.json';
schemas['template'] = template_schema;

