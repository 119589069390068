<settings class="mt-4 w-100">
  <x-container if={state.data}>
    <h5>Settings</h5>
    <x-fields schema={state.schema} data={state.data} edit={true} feedback={state.feedback} onchangevalue={onChange}></x-fields>
  </x-container>

  <script>
  import { router } from '@riotjs/route';
  export default {
    state: {
      schema: {
        type: 'object',
        properties: {
          openai_key: {
            label: 'OpenAI API key',
            type: 'string',
            description: 'You can find your OpenAI API key at <a href="https://platform.openai.com/account/api-keys">https://platform.openai.com/account/api-keys</a>.'
          },
          openai_save: {
            label: 'Save OpenAI API key remotely',
            type: 'boolean',
            description: '<ul><li>Enabled: your OpenAI API key will be saved remotely. You will be able to access your API key from any device.</li><li>Disabled: your OpenAI API key will be stored locally in your browser until you logout.</li></ul>'
          }
        },
      },
      feedback: {}
    },
    onBeforeUpdate() {
      if (global.loggedIn == undefined) {
        return;
      }

      if (global.loggedIn == false) {
        return router.push('/login');
      }

      const settings = global.loggedIn.settings || {};
      this.state.data = {
        openai_save: settings.openai_save,
        openai_key: settings.openai_save && settings.openai_key ? settings.openai_key : window.localStorage.getItem('openai_key')
      }

      // store the key locally, in case it was retrieved from the server
      if (this.state.data.openai_key != window.localStorage.getItem('openai_key')) {
        window.localStorage.setItem('openai_key', this.state.data.openai_key);
      }
    },
    async onChange(key, e) {
      const value = e.target.value;
      this.state.data[key] = value;
      
      // store in global
      global.loggedIn.settings = global.loggedIn.settings || {};
      global.loggedIn.settings[key] = value;

      switch (key) {
        case 'openai_key':
          // store locally
          window.localStorage.setItem('openai_key', value);

          // store remotely if enabled
          if (this.state.data.openai_save) {
            await put('/api/settings', { openai_key: value });
          }
          break;

        case 'openai_save':
          // store the key remotely if enabled, otherwise remove it
          await put('/api/settings', { openai_save: value, openai_key: value ? this.state.data.openai_key : null });
          break;
      }
    }
  };
  </script>
</settings>