<x-tool>
  <x-icon icon="question" onclick={toggleTip} />

  <style>
  x-icon {
    margin-left: 0.5rem;
    vertical-align: text-bottom;
  }
  x-icon svg, [is="x-icon"] svg {
    color: gray;
    width: 1rem;
    height: 1rem;
  }

  </style>

  <script>
  export default {
    toggleTip() {
      global.toggleTip(this.props.tip);
    }
  }
  </script>
</x-tool>