global.pad = function(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

global.dateString = function(time) {
    var t = new Date(parseInt(time));
    return pad(t.getDate(), 2) + "-" + pad(t.getMonth() + 1, 2) + "-" + t.getFullYear();
}

global.timeString = function(time) {
    var t = new Date(parseInt(time));
    return pad(t.getHours(), 2) + ":" + pad(t.getMinutes(), 2) + ":" + pad(t.getSeconds(), 2);
}

global.dateTimeString = function(time) {
    return dateString(time) + " - " + timeString(time);
}

if (typeof String.prototype.capitalize == 'undefined') {
    String.prototype.capitalize = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }
} else {
    debug("Function String.prototype.capitalize already exists!")
}

if (typeof String.prototype.escapeRegExp == 'undefined') {
    String.prototype.escapeRegExp = function() {
        return this.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }
} else {
    debug("Function String.prototype.escapeRegExp already exists!")
}

if (typeof Array.prototype.indexOfObject == 'undefined') {
    Array.prototype.indexOfObject = function(key, value) {
        for (var i = 0; i < this.length; i++) {
            if (this[i][key] == value) return i;
        }
        return -1;
    }
} else {
    debug("Function Array.prototype.indexOfObject already exists!")
}

if (typeof Array.prototype.findObject == 'undefined') {
    Array.prototype.findObject = function(key, value) {
        var index = this.indexOfObject(key, value);
        if (index > -1) {
            return this[index];
        } else {
            return undefined;
        }
    }
} else {
    debug("Function Array.prototype.findObject already exists!")
}