<x-summary>
  <x-card if={props.active === undefined} edit={props.edit} onselect={props.onselect} ondelete={props.ondelete} public={props.data.access == "public"}>
    <template slot="content">
      <div>{props.data.title}</div>
      <x-label if={props.data.tags}>{props.data.tags}</x-label>
    </template>
    <template if={props.showCopy != false} slot="edit">
      <x-icon icon="copy" onclick={this.onCopy}></x-icon>
    </template>
    <template slot="actions">
      <slot name="actions" />
    </template>
  </x-card>

  <x-card if={props.active === true} edit={props.edit} onselect={props.onselect} ondelete={props.ondelete} public={props.data.access == "public"}>
    <div slot="content" class="d-flex justify-content-between">
      <span class="summary"><slot name="content"></slot></span>
    </div>
    <template if={props.showCopy != false} slot="edit">
      <x-icon icon="copy" onclick={this.onCopy}></x-icon>
    </template>
    <template if={slots.findObject("id", "actions")}>
      <template  slot="actions">
        <slot name="actions" />
      </template>
    </template>
  </x-card>

  <div if={props.active === false} id="summary" class="summary" onclick={onClick} ondoubleclick={props.onselect}>
    <span ><slot name="content"></slot></span>
  </div>

  <style>
    .summary {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
              line-clamp: 3; 
      -webkit-box-orient: vertical;
    }
    .expanded {
      white-space: pre-wrap;
    }
  </style>

  <script>
    import { router } from '@riotjs/route';
    export default {
      async onCopy() {
        // get the name, removing the trailing "s"
        const name = this.props.name.slice(0, -1);

        // create new prompt with same data
        const schema = getSchema(name);

        // construct the update
        let update = constructUpdate(Object.keys(schema.properties), this.props.data);

        // add "copy" to the title
        update.title = `${update.title} copy`;

        // make private by default
        update.access = undefined;

        if(this.props.copyCustomize) {
          update = this.props.copyCustomize(update);
        }

        // send the new entity to the server
        let path = this.props.path.split('/');
        path.pop();
        path = path.join('/');
        const result = await post(`/api/${path}`, update);

        // notify the parent
        this.props.onneedupdate && this.props.onneedupdate();

        // navigate to the new entity
        router.push(`/${path}/${result._id}`);
      },
      onClick(e) {
        // navigate when double clicking
        if (this.props.viewDetail && e.detail > 1) {
          debug('double click', this.props);
          router.push(`/${this.props.path}`);
          return;
        }

        // prevent navigating when selecting text
        var selection = window.getSelection();
        if (selection.toString().length > 0) return; 

        // toggle the summary, note that relying on e.target is not reliable
        const target = this.$('div#summary');
        target.classList.toggle('summary');
        target.classList.toggle('expanded');
      }
    }
  </script>
</x-summary>