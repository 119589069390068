<message-edit>
  <x-entity-edit type="message" title="Message" path={props.path} show-edit={state.showEdit}>
  </x-entity-edit>

  <style>
    :host {
      display: flex;
      width: 100%;
    }
  </style>

  <script>
  export default {
    async onBeforeMount() {
      let prompt = await get(`/api/prompts/${this.props.promptId}`);
      this.state.prompt = prompt;
      this.update();
    },
    onBeforeUpdate() {
      const prompt = this.state.prompt;
      if (prompt) {
        this.state.showEdit = !prompt.responses || prompt.responses.length == 0;
      }
    }
  }
  </script>

</message-edit>