<popup>
<div class="modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{props.title}</h5>
        <button type="button" class="btn-close" onclick={onClose} aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <slot name="body"/>
      </div>
      <div class="modal-footer">
        <slot name="footer" onclose={onClose}/>
      </div>
    </div>
  </div>
</div>
<script>
  export default {
    onMounted() {
      let node = this.$('.modal');

      // pop the popup after the hide transition launched on close
      let that = this;
      node.addEventListener("hidden.bs.modal", function () {
        that.state.modal.dispose();
        that.popPopup();
      });

      this.state.modal = new bootstrap.Modal(node);
      this.state.modal.show();
    },
    onClose() {
      this.state.modal.hide();
    }
  }
</script>

</popup>

