<x-tip class={state.show ? "shown" : "hidden"}>
  <x-label if={props.html}>
    <x-raw html={props.html}></x-raw>
  </x-label>
  <x-label if={!props.html}>
    <slot />
  </x-label>

  <style>
  :host.hidden {
    display: none;
  }
  :host.shown {
    display: block;
  }
  ul {
    padding-left: 1.5rem; /* reduce indent */
    margin-block-end: 0; /* remove bottom margin */
  }
  </style>

  <script>
  global.tips = {};
  global.toggleTip = function(tip) {
    global.tips[tip] && global.tips[tip].toggle();
  }
  export default {
    onMounted() {
      global.tips[this.props.tip] = this;
    },
    onBeforeUnmount() {
      delete global.tips[this.props.tip];
    },
    toggle() {
      this.state.show = !this.state.show;
      this.update();
    },
  }
  </script>
</x-tip>