/*
 * This file is automatically generated by running `npm run update-components`
 */

import { register } from 'riot';

// Register all Riot.js components here
import { Route, Router } from '@riotjs/route'
register('router', Router)
register('route', Route)

import App from '../components/app.riot';
register('app', App);

import Home from '../components/home.riot';
register('home', Home);

import Login from '../components/login.riot';
register('login', Login);

import MessageEdit from '../components/message-edit.riot';
register('message-edit', MessageEdit);

import MessageSummary from '../components/message-summary.riot';
register('message-summary', MessageSummary);

import PipelineEdit from '../components/pipeline-edit.riot';
register('pipeline-edit', PipelineEdit);

import PipelineSummary from '../components/pipeline-summary.riot';
register('pipeline-summary', PipelineSummary);

import Pipeline from '../components/pipeline.riot';
register('pipeline', Pipeline);

import Popup from '../components/popup.riot';
register('popup', Popup);

import PromptSummary from '../components/prompt-summary.riot';
register('prompt-summary', PromptSummary);

import Prompt from '../components/prompt.riot';
register('prompt', Prompt);

import ResponseNew from '../components/response-new.riot';
register('response-new', ResponseNew);

import ResponseSummary from '../components/response-summary.riot';
register('response-summary', ResponseSummary);

import Response from '../components/response.riot';
register('response', Response);

import RunEdit from '../components/run-edit.riot';
register('run-edit', RunEdit);

import RunSummary from '../components/run-summary.riot';
register('run-summary', RunSummary);

import Run from '../components/run.riot';
register('run', Run);

import Settings from '../components/settings.riot';
register('settings', Settings);

import Signup from '../components/signup.riot';
register('signup', Signup);

import TemplateEd from '../components/template-ed.riot';
register('template-ed', TemplateEd);

import TemplateForm from '../components/template-form.riot';
register('template-form', TemplateForm);

import TemplateSummary from '../components/template-summary.riot';
register('template-summary', TemplateSummary);

import XBreadcrumbs from '../components/x-breadcrumbs.riot';
register('x-breadcrumbs', XBreadcrumbs);

import XButtonContainer from '../components/x-button-container.riot';
register('x-button-container', XButtonContainer);

import XButton from '../components/x-button.riot';
register('x-button', XButton);

import XCard from '../components/x-card.riot';
register('x-card', XCard);

import XCards from '../components/x-cards.riot';
register('x-cards', XCards);

import XContainer from '../components/x-container.riot';
register('x-container', XContainer);

import XEntityEdit from '../components/x-entity-edit.riot';
register('x-entity-edit', XEntityEdit);

import XEntityList from '../components/x-entity-list.riot';
register('x-entity-list', XEntityList);

import XEntityNew from '../components/x-entity-new.riot';
register('x-entity-new', XEntityNew);

import XError from '../components/x-error.riot';
register('x-error', XError);

import XField from '../components/x-field.riot';
register('x-field', XField);

import XFields from '../components/x-fields.riot';
register('x-fields', XFields);

import XIcon from '../components/x-icon.riot';
register('x-icon', XIcon);

import XLabel from '../components/x-label.riot';
register('x-label', XLabel);

import XOkCancel from '../components/x-ok-cancel.riot';
register('x-ok-cancel', XOkCancel);

import XRaw from '../components/x-raw.riot';
register('x-raw', XRaw);

import XSelect from '../components/x-select.riot';
register('x-select', XSelect);

import XSummary from '../components/x-summary.riot';
register('x-summary', XSummary);

import XSwitch from '../components/x-switch.riot';
register('x-switch', XSwitch);

import XTip from '../components/x-tip.riot';
register('x-tip', XTip);

import XTool from '../components/x-tool.riot';
register('x-tool', XTool);

