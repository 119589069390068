<prompt-summary>
  <x-summary type="template" name={props.name} path={props.path} copy-customize={copyCustomizes}
    data={props.data} edit={props.edit} onselect={props.onselect} ondelete={props.ondelete} onneedupdate={props.onneedupdate}></x-summary>

  <script>
  export default {
    copyCustomize(update) {
      // do not copy the responses
      delete update.responses;

      return update;
    }
  }
  </script>
</prompt-summary>