<pipeline-edit>
  <x-entity-edit type="pipeline" title="Pipeline" path={props.path}>
  </x-entity-edit>
  <x-button-container>
      <x-button onclick={onPlay}>Run pipeline</x-button>
  </x-button-container>
  <style>
    :host {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  </style>

  <script>
  import { router } from '@riotjs/route';
  export default {
    onPlay() {
      global.playPipeline(this.props.pipelineId);
    }
  }
  </script>

</pipeline-edit>