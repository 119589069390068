<x-cards>
  <div if={props.title || props.name} class={`d-flex justify-content-between mb-2`}>
    <x-label class={props.titleClass || "card-label"}>{props.title ? props.title : props.name.capitalize()} <x-tool if={state.tip} tip={props.name} /></x-label>
    <div if={props.edit}>
      <x-icon if={state.data && state.data.length > 0} icon="edit" active={state.edit} onclick={onEdit}></x-icon>
    </div>
  </div>
  
  <x-tip if={state.tip} tip={props.name}>{state.tip}</x-tip>
  
  <div is="{props.schema.items.name}-summary" each={data in state.data} data={data} edit={state.edit}
    active={props.active || props.active === undefined} name={props.name} path={props.path && `${props.path}/${data._id}`}
    onselect={props.path && onSelect.bind(this, data._id)}
    ondelete={props.path && onDelete.bind(this, data._id)}
    onneedupdate={props.onneedupdate}></div>

  <div if={props.edit && props.showAdd != false}  class="card shadow-inner user-select-none mt-2" onclick={onAdd}>
    <div class="card-body d-flex justify-content-center m-0 p-0">
      <div class="plus">+</div>
    </div>
  </div>

  <style>
    :host {
      width: 100%;
    }
    .card-label {
      font-size: 1.1rem;
      font-weight: 600;
    }
    .empty {
      color: #999;
      font-style: italic;
    }
    .shadow-inner {
      border: none;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) ;
      background-color: var(--bs-light-bg-subtle);
    }
    .plus {
      font-size: 2rem;
      background-color: #d5d5d5;
      color: transparent;
      text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
    }
    x-tip {
      top: -0.8rem;
      position: relative;
    }
  </style>

  <script>
  import { router } from '@riotjs/route';
  export default {
    state: {
      edit: false
    },
    onMounted() {
      //debug("x-cards.onMounted", this.props);

      this.state.tip = global.tooltips[this.props.name];
      this.update();
    },
    onBeforeUpdate() {
      //debug("x-cards.onBeforeUpdate", this.props, this.state)
      this.state.data = this.props.data || [];
    },
    onEdit() {
      this.state.edit = !this.state.edit;
      this.update();
    },
    onAdd() {
      if (this.props.schema.items.entitynew) {
        this.pushPopup(this.props.schema.items.entitynew, {
          path: `${this.props.path}`,
          onadd: this.props.onneedupdate
        });
      } else {
        this.pushPopup(`x-entity-new`, {
          type: this.props.schema.items.name,
          path: `${this.props.path}`,
          onadd: this.props.onneedupdate
        });
      }
    },
    onSelect(i) {
      // prevent navigating when selecting text
      var selection = window.getSelection();
      if (selection.toString().length > 0) return; 

      const path = `/${this.props.path}/${i}`;
      //debug("x-cards.onSelect", path);
      router.push(path);
    },
    async onDelete(i) {
      // delete the response on the server
      try{
        await del(`/api/${this.props.path}/${i}`);
      } catch(e) {
        debug("x-cards.onDelete", e);
        if (e.status == 403) {
          alert(`Sorry, you are not authorized to delete this ${this.props.name.slice(0, -1)}.`);
        } else {
          console.error(`Error trying to put /api/${this.props.path}`, e);
          alert(`Sorry, there was an error deleting the ${this.props.name.slice(0, -1)}.`);
        }
      }
      this.props.onneedupdate && this.props.onneedupdate();
    }
  }
  </script>
</x-cards>