<x-fields class="d-flex flex-column row-gap-2">
  <x-field each={field in state.fields}
    name={field.name}
    data={field.data}
    feedback={field.feedback}
    schema={field.schema}
    path={props.path}
    onchangevalue={onChange}
    onneedupdate={props.onneedupdate}
    edit={props.edit && (!props.showEdit
      || (typeof props.showEdit == "boolean" && props.showEdit)
      || (typeof props.showEdit == "object" && props.showEdit.indexOf(field.name) > -1))}
    show-add={!props.showAdd
      || (typeof props.showAdd == "boolean" && props.showAdd)
      || (typeof props.showAdd == "object" && props.showAdd.indexOf(field.name) > -1)}></x-field>

  <slot/>
  
  <script>
  export default {
    onBeforeMount() {
      //debug("x-fields.onBeforeMount", this.props)
      this.state.fields = Object.entries(this.props.schema.properties).map(([key, value]) => {
        return {
          name: key,
          schema: value,
          data: this.props.data && this.props.data[key],
          feedback: this.props.feedback && this.props.feedback[key]
        }
      }).filter(field => !field.schema.hidden && (this.props.edit || (field.data != undefined && field.data != "")));
      debug("x-fields.onBeforeMount fields", this.state.fields)
    },
    onBeforeUpdate() {
      this.onBeforeMount();
    },
    onChange(key, e) {
      //debug("x-fields.onChange", this.props.name, key, e);
      this.props.onchangevalue && this.props.onchangevalue((this.props.name ? this.props.name + "." : "") + key, e);

      // if it is the last field, then onenter will be called
      if (this.props.onenter && this.state.fields[this.state.fields.length - 1].name == key) {
        this.props.onenter();
      }
    }
  }
  </script>
</x-fields>