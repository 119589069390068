<template-form class="mt-4 w-100">
  <x-container if={state.schema}>
    <div class="d-flex flex-column row-gap-2">
      <h5>{state.template && state.template.title}</h5>
      <x-field each={key in Object.keys(state.schema)} name={key} schema={state.schema[key]} data={state.data[key]} edit={true}
        onchangevalue={onChange}></x-field>

      <h6 class="mt-4" onclick={onTogglePreview}>Preview</h6>
      <template if={state.showPreview}>
        <x-cards schema={{items: {name: "message"}}} data={state.messagesFilled} edit={false} active={false}></x-cards>
      </template>

      <x-button-container if={state.showPlay}>
        <x-button if={!this.props.runId} onclick={onPlayPrompt}>Create prompt</x-button>
        <x-button onclick={onPlay}>Run</x-button>
      </x-button-container>
    </div>
  </x-container>

  <style>
    .host {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  </style>

  <script>
  import { router } from '@riotjs/route';
  export default {
    state: {
      schema: undefined,
      data: {},
      showPreview: true,
      showPlay: true
    },
    async variablesToSchema(template) {
      // if par to of a run, then get the run
      let run;
      if (this.props.runId) {
        run = await get(`/api/pipelines/${this.props.pipelineId}/runs/${this.props.runId}`);
        this.state.run = run;
      }

      // extract the variable names form the variable placeholders between {{ and }} from the content of all messages
      // the variable names should be unique
      // each variable name shouold be accompanied by a schema definition
      // the schema definition should distinguish: text between {{ and }}, textarea between {{ and *}}
      let schema = {};
      let matches = template.messages
        .map(m => (m.content || "") // get the content of the message
        .match(/{{([^}]+)}}/g)) // get all variables between {{ and }}
        .flat() // flatten the array of arrays
        .filter(x => x) // remove all nulls

      // if no matches found, then return empty schema
      if (matches.length == 0) return schema;
      
      matches.forEach(m => {
        let key, textarea;
        if (m.match(/{{([^*]+)\*}}/)) {
          key = m.replace(/{{([^*]+)\*}}/, '$1');
          textarea = true;
        } else {
          key = m.replace(/{{([^}]+)}}/, '$1');
        }

        // check if the key is already in the schema and if the textarea is true
        // if not, set it to true
        if (schema[key] && schema[key].textarea) {
          textarea = true;
        }

        // if key starts with '$' then load its content from the corresponding prompt response, start indexing at 1
        let editable = true;
        //debug("template-form.variablesToSchema", key)
        if (this.props.runId && key.startsWith('$')) {
          const index = Number.parseInt(key.match(/\$([0-9]+)/)[1]) - 1;
          const template = run.templates && run.templates.length > index && run.templates[index];
          const prompt = template && template.prompts && template.prompts.length && template.prompts[template.prompts.length - 1];

          //debug("template-form.variablesToSchema", key, index, prompt, run.prompts);

          // take the first response from the prompt
          if (prompt.responses && prompt.responses.length) {
            this.state.data[key] = prompt.responses[0].response;
          }
          editable = false;
        }

        schema[key] = {
          type: 'string',
          textarea: textarea,
          editable: editable,
          disabled: !editable
        }
      });
      
      return schema;
    },
    async onUpdated() {
      // replace onBeforeMount with onUpdated, because the templateId can change
      if (this.lastTemplateId == this.props.templateId) return;

      this.lastTemplateId = this.props.templateId;

      debug('template-form.onUpdated', this.props, this.state);
      
      // set path prefix if part of a run or pipeline
      if (this.props.runId) {
        this.state.prefix = `/pipelines/${this.props.pipelineId}/runs/${this.props.runId}/templates/${this.props.templateId}`
      } else if (this.props.pipelineId) {
        this.state.prefix = `/pipelines/${this.props.pipelineId}/templates/${this.props.templateId}`;
      } else {
        this.state.prefix = `/templates/${this.props.templateId}`
      }
      
      let template = await get(`/api${this.state.prefix}`);
      this.state.template = template;

      this.state.messagesFilled = template.messages;
      this.state.schemaMessages = getSchema('message');

      const schema = await this.variablesToSchema(template);
      this.state.schema = schema;

      this.fillMessages();
      debug('template-form.onUpdated 2', this.props, this.state);
      this.update();

      // setup tooltips for the icons
      var tooltipTriggerList = this.$$('[data-bs-toggle="tooltip"]');
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, {
          delay: { "show": 1000, "hide": 100 }
        })
      })
    },
    fillMessages() {
      // fill the messages with the data
      let messagesFilled = this.state.template.messages.map(m => {
        let content = m.content;
        Object.keys(this.state.data).forEach(key => {
          let value = this.state.data[key];
          key = key.escapeRegExp();
          
          // replace all variables in the content, in case both {{key}} and {{key*}} are used
          content = content.replace(new RegExp(`{{${key}\\*}}`, 'g'), value);
          content = content.replace(new RegExp(`{{${key}}}`, 'g'), value);
        });
        return {
          ...m,
          content: content
        }
      });
      this.state.messagesFilled = messagesFilled;
    },
    onChange(key, e) {
      this.state.data[key] = e.target.value;

      // fill the messages with the data
      this.fillMessages();

      this.update();
    },
    onTogglePreview() {
      this.state.showPreview = !this.state.showPreview;
      this.update();
    },
    async createPrompt() {
      // create new prompt with same data
      const now = (new Date()).getTime();
      const schema = getSchema("prompt");
      const prompt = {
        templateId: this.props.templateId,

        // construct the title from the template title and the data
        title: `${this.state.template.title}: ${Object.values(this.state.data).map(x => {
          if (x.length < 20) return x;
          return x.slice(0, 20-3) + "...";
        }).join(", ")}`,

        // construct the tags from the template tags and the current date
        tags: (this.state.template.tags || "").split(" ").map(x => x.trim()).concat(["template", dateString(now), timeString(now)]).join(" "),

        // construct the messages from the template messages and the data
        messages: this.state.messagesFilled
      };

      // construct the update
      const update = constructUpdate(Object.keys(schema.properties), prompt);

      // send the new entity to the server
      const result = await post(`/api${this.state.prefix}/prompts`, update);

      return result._id;
    },
    async onPlayPrompt() {
      const promptId = await this.createPrompt();
      router.push(`${this.state.prefix}/prompts/${promptId}`);
    },
    async onPlay() {
      // show the new response popup
      let that = this;
      this.pushPopup('response-new', {
        template: this.state.template,
        getPromptPath: async function() {
          const promptId = await that.createPrompt();
          return `${that.state.prefix}/prompts/${promptId}`
        },
        onadd: function (promptId, responseId) {
          // navigate to the new response
          let path = `${that.state.prefix}/prompts/${promptId}/responses/${responseId}`;
          
          // if the template is part of a run, and this is not the last template, then navigate to the next template instead
          if (that.props.runId) {
            const templateId = nextTemplateId(that.state.run, 1);
            if (templateId) {
              // navigate to the next template form
              path = `/pipelines/${that.props.pipelineId}/runs/${that.props.runId}/templates/${templateId}/form?r=${(new Date()).getTime()}`;
            } else {
              // navigate to the run
              path = `/pipelines/${that.props.pipelineId}/runs/${that.props.runId}`;
            }
          }

          router.push(path);
        }
      });
    }
  }
  </script>
</template-form>