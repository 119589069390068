import { getSchema } from '../../schema/schemas.js';
global.getSchema = getSchema;

import { component, install } from 'riot';
import { router } from '@riotjs/route';

import './riot-components';
import App from '../components/app.riot';

import PopupMixin from './riot-popup';
install(PopupMixin);

import UidMixin from './riot-uid';
install(UidMixin);

if (process.env.NODE_ENV !== 'production') {
    global.debug = function(...args) {
        console.log(...args);
    }
} else {
    global.debug = function() {}
}

// fix for json-schema-ref-parser
//import fetch from "node-fetch";
//globalThis.fetch = fetch;

async function handleResult(method, result) {
    if (!result.ok) {
        debug(method, result);
        let err = new Error(result.statusText);
        err.status = result.status;
        err.url = result.url;
        err.statusText = result.statusText;
        err.redirected = result.redirected;
        try {
            err.json = await result.json();
        } catch (e) {}
        try {
            err.text = await result.text();
        } catch (e) {}
        throw err;
    }
    if (result.redirected) {
        debug(method, "redirect", result);
        // use window.location.href instead of router.push to reload the page (e.g. after login)
        window.location.href = result.url;
        //router.push(result.url);
        return;
    }
    try {
        const json = await result.json();
        debug(method, json);
        return json;
    } catch (e) {
        debug(method, "no json", e, result);
        return undefined;
    }
}

global.get = async function(url) {
    debug("GET", url);
    const result = await fetch(url);
    return await handleResult("get", result);
}

global.post = async function(url, body) {
    debug("POST", url, body);
    const result = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });
    return await handleResult("post", result);
}

global.put = async function(url, body) {
    debug("PUT", url, body);
    const result = await fetch(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });
    return await handleResult("put", result);
}

global.del = async function(url) {
    debug("DELETE", url);
    const result = await fetch(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        }
    });
    return await handleResult("delete", result);
}

// construct an update object from a list of keys and a state object
global.constructUpdate = function(keys, state) {
    const update = {};
    keys.forEach(key => {
        if (state[key]) {
            update[key] = state[key];
        }
    });
    return update;
}

const _price = {
    "gpt-4-turbo": {
        prompt: 0.01 / 1000,
        completion: 0.03 / 1000
    },
    "gpt-4-turbo-2024-04-09": {
        prompt: 0.01 / 1000,
        completion: 0.03 / 1000
    }, 
    "gpt-4-turbo-preview": {
        prompt: 0.01 / 1000,
        completion: 0.03 / 1000
    },
     "gpt-4-0125-preview": {
        prompt: 0.01 / 1000,
        completion: 0.03 / 1000
    }, 
     "gpt-4-1106-preview": {
        prompt: 0.01 / 1000,
        completion: 0.03 / 1000
    }, 
     "gpt-4": {
        prompt: 0.03 / 1000,
        completion: 0.06 / 1000
    },
    "gpt-4-0613": {
        prompt: 0.03 / 1000,
        completion: 0.06 / 1000
    }, 
     "gpt-4-0314": {
        prompt: 0.03 / 1000,
        completion: 0.06 / 1000
    },
     "gpt-3.5-turbo": {
        prompt: 0.5 / 1000000,
        completion: 1.5 / 1000000
    },
      "gpt-3.5-turbo-0125": {
        prompt: 0.5 / 1000000,
        completion: 1.5 / 1000000
    }, 
      "gpt-3.5-turbo-1106": {
        prompt: 0.5 / 1000000,
        completion: 1.5 / 1000000
    },

    'gpt-4-0125': {
        prompt: 0.01 / 1000,
        completion: 0.03 / 1000
    },
    'gpt-4-1106': {
        prompt: 0.01 / 1000,
        completion: 0.03 / 1000
    },
    'gpt-4-32k': {
        prompt: 0.06 / 1000,
        completion: 0.12 / 1000
    },
    'gpt-3.5-turbo-16k': {
        prompt: 0.003 / 1000,
        completion: 0.004 / 1000
    },
}

// compute the cost of a response
global.computeCost = function(model, usage) {
    if (!model || !usage) return undefined;

    //const baseModel = model.split('-').slice(0, -1).join('-');
    const baseModel = model;
    
    const price = _price[baseModel];
    if (!price) {
        console.error("price not found for", baseModel);
        return undefined;
    }
    let cost = usage.prompt_tokens * price.prompt + usage.completion_tokens * price.completion;
    return Math.round(cost * 10000) / 10000;
}

global.nextTemplateId = function(run, offset = 0) {
    // get the id of the first template without a prompt
    let i = 0;
    for (; i < (run.templates || []).length; i++) {
        if (!(run.templates[i].prompts && run.templates[i].prompts.length)) {
            break;
        }
    }
    i = i + offset;
    return (run.templates || []).length > i ? run.templates[i]._id : undefined;
}

global.playPipeline = async function(pipelineId) {
    let run = await post(`/api/pipelines/${pipelineId}/runs/new`);
    run = await get(`/api/pipelines/${pipelineId}/runs/${run._id}`);
    const templateId = nextTemplateId(run);
    if (templateId) {
        router.push(`/pipelines/${pipelineId}/runs/${run._id}/templates/${templateId}/form`);
    } else {
        router.push(`/pipelines/${pipelineId}/runs/${run._id}`);
    }
}

// extracting the id following a kind in the path, which can have e.g. the following formats:
//    templates/{templateId}
//    pipelines/{pipelineId}/templates/{templateId}
//    runs/{runId}/templates/{templateId}
global.extractId = function(path, kind) {
    if (path) {
        let parts = path.split("/");
        let index = parts.indexOf(kind);
        if (index >= 0 && index < parts.length - 1) {
        return parts[index + 1];
        }
    }
    return null;
}

global.getPath = function() {
    return window.location.pathname.replace(/(^\/+|\/+$)/mg, '');
}

global.tooltips = {
    prompts: "A prompt is a sequence of messages resembling a typical ChatGPT conversation. \
    When executing a prompt you can specify all the parameters accepted by the \
    OpenAI API (e.g. model, temperature, max number of tokens, ...). You can \
    execute a prompt multiple times, with different parameters. All responses are saved, \
    together with execution information, such as the timing and cost of the response.",
    templates: "A template is a prompt with placeholders that are filled in when executing the template.",
    pipelines: "A pipeline is a sequence of templates containing placeholders for responses of previous prompts in the pipeline.",
    runs: "A run is an execution of a pipeline. You can execute a run multiple times, with different parameters. All responses are saved."

}

// load the utils
import {} from '../../lib/utils.js';

global.clone = function(obj) {
    return JSON.parse(JSON.stringify(obj));
}

// mount the main component
component(App)(document.querySelector('app'));