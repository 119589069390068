<prompt class="d-flex flex-column row-gap-2">
  <template if={props.data && state.schema}>
    <x-fields schema={state.schema} data={props.data} edit={props.edit}
      onchangevalue={props.onchangevalue} onneedupdate={props.onneedupdate}></x-fields>
    <x-field name="messages" schema={state.schemaMessages} data={props.data.messages} edit={state.editMessages} path={props.path} active={state.editMessages}
      onchangevalue={props.onchangevalue} onneedupdate={props.onneedupdate}></x-field>

    <x-button-container>
      <x-button onclick={onPlay}>Run prompt</x-button>
    </x-button-container>
    
    <x-field name="responses" schema={state.schemaResponses} data={state.responses} edit={state.editResponses} path={props.path} show-add={false}
      onchangevalue={props.onchangevalue} onneedupdate={props.onneedupdate}></x-field>
  </template>

  <script>
  export default {
    onBeforeMount() {
      //debug('prompt.onBeforeMount', this.props, this.state);
      this.state.schema = clone(this.props.schema);

      // get the responses schema
      this.state.schemaMessages = this.state.schema.properties.messages;
      this.state.schemaResponses = this.state.schema.properties.responses;

      // hide the api and args fields
      delete this.state.schema.properties.messages;
      delete this.state.schema.properties.responses;
    },
    onMounted() {
      this.update();
    },
    onBeforeUpdate() {
      const run = this.props.path.split('/')[0] == 'runs';
      const runId = (run && this.props.path.split('/')[1]) || undefined;

      this.state.editMessages = !(this.props.data.responses && this.props.data.responses.length > 0) && !run;
      this.state.editResponses = (this.props.data.messages && this.props.data.messages.length > 0) && !run;

      // add the prompt id to the responses
      const promptId = this.props.data._id;
      this.state.responses = (this.props.data.responses || []).map((a, i) => {
        a.promptId = promptId;
        a.runId = runId;
        return a;
      });
    },
    onPlay() {
      this.pushPopup("response-new", {
        getPromptPath: () => this.props.path,
        onadd: this.props.onneedupdate
      });
    }
  }
  </script>
</prompt>