<x-ok-cancel class="d-flex justify-content-between my-3">
  <div>
    <button if={props.ondelete} type="button" class="btn btn-warning" onclick={props.ondelete}>{props.delete || "Delete"}</button>
  </div>
  <div>
    <button type="button" id="cancel" class="btn btn-secondary btn-sm" onclick={props.oncancel} disabled={props.busy}>{props.cancel || "Cancel"}</button>

    <button if={!props.busy} type="button" class="btn btn-primary btn-sm" onclick={onok}>{props.ok || "OK"}</button>
  
    <button if={props.busy} type="button" class="btn btn-primary btn-sm" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>

  </div>
  <style>
    #cancel {
      margin-right: 0.5rem;
    }
  </style>

  <script>
    export default {
      onok() {
        this.props.onok && this.props.onok();
      }
    }
  </script>
</x-ok-cancel>