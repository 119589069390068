<x-breadcrumbs>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li each={part in state.crumbs.slice(0, -1)} class="breadcrumb-item"><a href={"/" + part.path}>{part.label}</a></li>
      <li class="breadcrumb-item active">{state.crumbs && state.crumbs.length ? state.crumbs[state.crumbs.length - 1].label : ""}</li>
    </ol>
  </nav>

  <script>
  export default {
    onBeforeMount() {
      // path is a string like 'runs/1234/responses/5678', alternating between the type and the id
      const parts = this.props.path.split('/');

      // make the crumbs, showing the type as the label, linking to the id
      let crumbs = [];
      for (let i = 0; i < parts.length; i += 2) {
        crumbs.push({
          label: parts[i].replace(/s$/, '').capitalize(),
          path: parts.slice(0, i + 2).join('/')
        });
      }
      this.state.crumbs = crumbs;
    },
    onBeforeUpdate() {
    }
  }
  </script>
</x-breadcrumbs>